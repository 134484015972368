<template>
  <v-app>
    <b-container fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('customer_.shop_settings') }}</h4>
        </template>
        <template v-slot:body>
          <div class="form-group row align-items-center">
            <label class="col-md-3">{{ $t('employee.display') }}</label>
            <div class="col-md-9">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="display_marketplace" v-model="display_marketplace">
                <label class="custom-control-label" for="display_marketplace">{{ $t('customer_.display_on_marketplace') }}</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="display_app" v-model="display_app">
                <label class="custom-control-label" for="display_app">{{ $t('customer_.display_on_app') }}</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="display_website" v-model="display_website">
                <label class="custom-control-label" for="display_website">{{ $t('employee.display_on_website') }}</label>
              </div>
              <div v-if="display_website" class="card" style="background:black;">
                <pre class="text-white">
                  <code>
                  &lt;div id="12booky_shop"&gt;&lt;/div&gt;
                  &lt;script type="text/javascript" src="https://shop.12booky.com/js/embeed.js?token={{ companyToken }}"&gt;&lt;/script&gt;
                  </code>
                </pre>
              </div>
            </div>
          </div>

          <b-button type="submit" @click="saveSettings" variant="primary" class="mr-2">{{ $t('employee.submit') }}</b-button>
        </template>
      </iq-card>
    </b-container>
  </v-app>
</template>
<script>
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'Settings',
  mounted () {
    core.index()
    this.getSettings()
  },
  data () {
    return {
      display_website: false,
      display_app: false,
      display_marketplace: false,
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || ''
    }
  },
  methods: {
    getSettings () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_SETTINGS)
        .then((response) => {
          if (response.data.status === 'success') {
            const thisSettings = JSON.parse(response.data.settings.setting)
            this.display_website = thisSettings.display_website
            this.display_app = thisSettings.display_app
            this.display_marketplace = thisSettings.display_marketplace
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    saveSettings () {
      const thisSettings = {
        display: {
          display_marketplace: this.display_marketplace,
          display_app: this.display_app,
          display_website: this.display_website
        }
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SAVE_SETTINGS, thisSettings)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('customer_.settings_saved'))
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  }
}
</script>
